import React from 'react'

const FormContent = ({id, activeTab, children}) => {
  return (
    activeTab === id ? <div className="TabContent">
     { children }
   </div>
   : null
  )
}

export default FormContent