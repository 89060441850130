import React from "react";
import "./Services.scss";
import icon1 from "../../Assets/Icons/serviceIcon1.svg";
import icon2 from "../../Assets/Icons/serviceIcon2.svg";
import icon3 from "../../Assets/Icons/serviceIcon3.svg";
import laptop from "../../Assets/Images/laptop.png";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
const Services = () => {
  const { t } = useTranslation();
  return (
    // <div className="service-container-main ">
    <div className="container-wrapper service-wrapper">
      <div className="services">
        <div className="container-boxx ">
          <Fade bottom distance="20%">
            <div className="service-title">
              <h2>{t("service.title")}</h2>
            </div>
          </Fade>
          <Fade bottom distance="20%" cascade>
            <div className="service-card-wrapper">
              <div className="service-card">
                <div className="card-content">
                  <h2>1.</h2>
                  <div className="service-content-wrapper">
                    <div className="icon">
                      <img src={icon1} alt="" />
                    </div>
                    <div className="content">
                      <div className="title">{t("service.cardTitle")}</div>
                      <div className="desc">{t("service.cardText")}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-card">
                <div className="card-content">
                  <h2>2.</h2>
                  <div className="service-content-wrapper">
                    <div className="icon">
                      <img src={icon2} alt="" />
                    </div>
                    <div className="content">
                      <div className="title">{t("service.cardTitle2")}</div>
                      <div className="desc">{t("service.cardText2")}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-card">
                <div className="card-content">
                  <h2>3.</h2>
                  <div className="service-content-wrapper">
                    <div className="icon">
                      <img src={icon3} alt="" />
                    </div>
                    <div className="content">
                      <div className="title">{t("service.cardTitle3")}</div>
                      <div className="desc">{t("service.cardText3")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <Fade bottom distance="5%" >
          <div className="service-image">
            <img src={laptop} alt="" />
          </div>
        </Fade>
      </div>
    </div>
    // {/* </div> */}
  );
};

export default Services;
