import React, { useState, useEffect, useRef } from "react";
import "./Navbar.scss";
import logo from "../../Assets/Images/LOGO.svg";
import newLogo from "../../Assets/Images/FullLogo.svg"
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import phone from "../../Assets/Icons/Phone.svg";
import Whatsapp from "../../Assets/Icons/WhatsApp.svg";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t } = useTranslation();
  const handleScroll = () => {
    const footer = document.getElementById("footer");
    footer.scrollIntoView({ behavior: "smooth" });
  };

  const [show, handleShow] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        handleShow(true);
      } else {
        handleShow(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", null);
    };
    console.log(show);
  }, [window.scrollY]);

  return (
    <div className="container-wrapper">
      <div className="container-boxx nav-container">
        <div className={`navbar_main ${show && "navbar-hide"}`}>
          <div className="navlogo">
            <img src={newLogo} alt="" />
          </div>
          <div className="contact-button">
            <Box sx={{ display: "flex", gap: "16px" }}>
              <Button
                sx={{
                  color: "#fff",
                  width: "60px",
                  height: "60px",
                  border: "2px solid white",
                  padding: { sm: "16px", xs: "5px" },
                  borderRadius: "16px",
                  background:
                    "linear-gradient(115deg, rgba(255, 255, 255, 0.39) 0%, rgba(255, 255, 255, 0.19) 100%);",
                }}
                variant="outlined"
              >
                <img src={Whatsapp} alt="" />
              </Button>
              <Button
                sx={{
                  color: "#fff",
                  width: { sm: "178px", xs: "60px" },
                  height: "60px",
                  border: "2px solid white",
                  padding: { sm: "16px", xs: "5px" },
                  borderRadius: "16px",
                  textTransform: "capitalize",
                  background:
                    "linear-gradient(115deg, rgba(255, 255, 255, 0.39) 0%, rgba(255, 255, 255, 0.19) 100%);",
                  // display: { sm: 'block', xs: 'none'}
                }}
                variant="outlined"
                onClick={handleScroll}
              >
                <div className="btn-text">
                  <img src={phone} alt="" />
                  <span className="contact-btn-txt">
                    {t("Navbar.contact")}{" "}
                  </span>{" "}
                </div>
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
