import React, {Suspense} from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";

const languages = ["en", "he"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "he"],
    lng: document.querySelector("html").lang,
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    Backend: {
      loadPath: "//Frontend/public/locales{{lng}}/translation.json",
    },
    
  });

export default i18n;
