import React from "react";
import "./Whyus.scss";
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";

const Whyus = () => {
  const { t } = useTranslation();
  const cardContentLeft = [
    {
      icon: "Tick.svg",
      text: t("whyus.cardTitle1"),
      desc: t("whyus.cardText1"),
    },
    {
      icon: "Tick.svg",
      text: t("whyus.cardTitle2"),
      desc: t("whyus.cardText2"),
    },
    {
      icon: "Tick.svg",
      text: t("whyus.cardTitle3"),
      desc: t("whyus.cardText3"),
    },
    {
      icon: "Tick.svg",
      text: t("whyus.cardTitle4"),
      desc: t("whyus.cardText4"),
    },
  ];
  const cardContentRight = [
    {
      icon: "Tick.svg",
      text: t("whyus.cardTitle5"),
      desc: t("whyus.cardText5"),
      //   desc: "Our goal is to let your money work, maintain its value and earn with it",
    },
    {
      icon: "Tick.svg",
      text: t("whyus.cardTitle6"),
      desc: t("whyus.cardText6"),
    },
    {
      icon: "Tick.svg",
      text: t("whyus.cardTitle7"),
      desc: t("whyus.cardText7"),
      //   desc: "With the help of AI, we locate and analyze the properties and find the relevant investment directions for your requirements.",
    },
    {
      icon: "Tick.svg",
      text: t("whyus.cardTitle8"),
      desc: t("whyus.cardText8"),
      //   desc: "The value of the property is affected by many indicators, urban renewal and rights, what is in the vicinity, what is accessible and what is not... these and many others affect the value of the property, we will know how to locate them.",
    },
  ];
  return (
    <div className="container-wrapper">
      <div className="container-boxx whyus-wrapper">
        <Fade bottom distance="20%">
          <div className="whyus-title">
            <h2>
              {t("whyus.title1")}
              <span className="colored">{t("whyus.color")}</span>
              {t("whyus.title2")}
            </h2>
          </div>
        </Fade>
        <div className="whyus-card-container">
          <div className="whyus-content-wrapper-left">
            {cardContentLeft.map((data) => {
              return (
                <Fade bottom cascade distance="20%">
                  <div>
                    <div className="whyus-cards">
                      <div className="tick-icon">
                        <img src={data.icon} alt="" />
                      </div>
                      <div className="whyus-content">
                        <div className="whyus-text">{data.text}</div>
                        <div className="whyus-desc">{data.desc}</div>
                      </div>
                    </div>
                  </div>
                </Fade>
              );
            })}
          </div>
          <div className="whyus-content-wrapper-right">
            {cardContentRight.map((data) => {
              return (
                <Fade  bottom cascade distance="20%">
                  <div>
                    <div className="whyus-cards">
                      <div className="tick-icon">
                        <img src={data.icon} alt="" />
                      </div>
                      <div className="whyus-content">
                        <div className="whyus-text">{data.text}</div>
                        <div className="whyus-desc">{data.desc}</div>
                      </div>
                    </div>
                  </div>
                </Fade>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whyus;
