import React from "react";
import ComingSoon from "../Components/ComingSoon/ComingSoon";

const LandingPage = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default LandingPage;
