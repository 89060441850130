import React, { useState } from "react";
import "./ComingSoon.scss";
import Button from "@mui/material/Button";
import Message from "../../Assets/Icons/Message.svg";
import { useTranslation } from "react-i18next";
import Logo from "../../Assets/Images/FullLogo.svg";
import Person from "../../Assets/Icons/Person.svg";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { toast } from "react-toastify";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const ComingSoon = () => {
  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
  });

  function handleStateChanges(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    // console.log(mailerState)
  }
  const submitEmailLanding = async (e) => {
    e.preventDefault();

    //console.log("regobj");
    fetch("http://localhost:8000/send", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ mailerState }),
    })
      .then((res) => {
        // alert("message sent successfully.");
        toast.success("message sent successfully.");
        window.location.reload(); // after something happened all thing located on current window will be reloaded
        console.log(res);
      })
      .catch((err) => {
        toast.error("Failed :" + err.message);
      });
  };
  const { t } = useTranslation();

  return (
    <div className="banner-container-landing landing-container-wrapper main-container">
      <div className="landing-banner-content container-boxx">
        <div className="coming-soon-wrapper">
          <div className="coming-soon">
            Coming Soon <span>!</span>
          </div>
          <div className="coming-soon-left"></div>
        </div>
        <div className="landing-content-wrapper">
          <div className="coming-soon-intro">
            <div className="logo">
              <img src={Logo} alt="" />
            </div>
            <div className=" landing-headline ">
              <h1>
                ניתוח נדלן מתקדם, <br />
                חדשני, מבוסס נתונים ו-AI
              </h1>
              <div className="landing-text">
                מערכת שמאגדת את כול נתוני הנדלן בארץ למקום אחד ובעזרת כלים
                מתקדמים ובינה מלאכותית אנו מציגים תובנות עסקיות
              </div>
            </div>
            <div className="email-header">
              מעוניינים בפרטים? תשאירו מייל ונחזור אליכם
            </div>
            <div className="email-address">
            Info@datapoints.co.il
            </div>

            {/* <ValidatorForm onSubmit={submitEmailLanding}>
              <Box
                component="form"
                fullWidth
                width="100%"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <FormControl fullWidth>
                  <TextValidator
                    validators={["required"]}
                    errorMessages={[
                      "this field is required",
                      "name is not valid",
                    ]}
                    type="text"
                    name="name"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Person} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Full Name"
                    sx={{
                      background: "#fff",
                      borderRadius: "12px",
                      width: "100%",
                      fontSize: "16px",
                    }}
                    transformOrigin="right"
                    variant="outlined"
                    onChange={handleStateChanges}
                    value={mailerState.name}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    border: "none",
                  }}
                >
                  <TextValidator
                    className="input-field-email"
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "this field is required",
                      "email is not valid",
                    ]}
                    name="email"
                    type="email"
                    sx={{
                      background: "#fff",
                      borderRadius: "12px",
                      border: "none",
                      width: "100%",
                      fontSize: "16px",
                    }}
                    placeholder="Email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Message} alt="" />
                        </InputAdornment>
                      ),
                      style: {
                        border: "none",
                      },
                    }}
                    variant="outlined"
                    onChange={handleStateChanges}
                    value={mailerState.email}
                  />
                </FormControl>
                <div className="email-btn">
                  <Button
                    className="banner-btn"
                    sx={{
                      color: "#fff",
                      //width:"150px",
                      height: "34px",
                      border: "1px solid white",
                      padding: "24px 24px",
                      borderRadius: "12px",
                      background:
                        "linear-gradient(115deg, rgba(255, 255, 255, 0.39) 0%, rgba(255, 255, 255, 0.19) 100%);",
                      textAlign: "center",
                    }}
                    variant="outlined"
                    type="submit"
                  >
                    <span className="banner-btn2">שלחו</span>
                  </Button>
                </div>
              </Box>
            </ValidatorForm> */}
          </div>
          <div className="landing-text-box-container landing-text-box-wrapper">
            <div className="text-box l-box-1 floating">
              <div className="text-box-title">{t("Banner.float")}</div>
              <div className="text-box-value">{t("Banner.profitValue1")}</div>
              <div className="text-box-desc">{t("Banner.profitTime1")}</div>
              <span className="box-arrow"></span>
            </div>
            <div className="text-box l-box-2 floating">
              <div className="text-box-title">{t("Banner.float")}</div>
              <div className="text-box-value">{t("Banner.profitValue2")}</div>
              <div className="text-box-desc">{t("Banner.profitTime2")}</div>
              <span className="box-arrow"></span>
            </div>
            <div className="text-box l-box-3 floating2">
              <div className="text-box-title">{t("Banner.float")}</div>
              <div className="text-box-value">{t("Banner.profitValue3")}</div>
              <div className="text-box-desc">{t("Banner.profitTime3")}</div>
              <span className="box-arrow"></span>
            </div>
          </div>
        </div>
        <div className="foot-note-wrapper">
          <div className="foot-note">
            <span>Datapoints</span>Powered by
          </div>
          <div className="foot-note-left"></div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
