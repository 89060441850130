import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import LandingPage from "./pages/LandingPage";
import { Routes, Route } from "react-router";
function App() {
  return (
    <>
      <ToastContainer 
        theme="top-left"
        position="center"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        //rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/old" element={<Home />} />
      </Routes>
      </>
  );
}

export default App;
