import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Send from "../../Assets/Icons/Send.svg";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const Popup = (props) => {
  const { t } = useTranslation();
  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
    purpose: "",
    time: "",
    message: "",
  });

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    console.log(mailerState)
  }
  const submitEmail = async (e) => {
    e.preventDefault();
   
    
        //console.log(regobj);
        fetch("http://localhost:8000/sendpopup", {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({ mailerState }),
        })
          .then((res) => {
            // alert("message sent successfully.");
            toast.success("message sent successfully.");
            window.location.reload(); // after something happened all thing located on current window will be reloaded
            console.log(res)
          })
          .catch((err) => {
            toast.error("Failed :" + err.message);
          });
    
 
  };
 

  return props.trigger ? (
    <div className="container-wrapper popup">
      <div className="container-boxx popup-wrapper">
        <div className="popup-inner ">
          <IconButton
            onClick={() => props.setTrigger(false)}
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              maxWidth: "100%",
            }}
          >
            <CloseIcon className="close-btn" />
          </IconButton>
          <div className="form-header">
            <h2>{t("popup.title")}</h2>
            <div className="form-header-text">{t("popup.text")}</div>
          </div>
          {/* onSubmit={submitEmail} */}
          <ValidatorForm onSubmit={submitEmail}>
            <div className="input-fields">
              <div className="top-input">
                <Box
                  component="form"
                  fullWidth
                  sx={{
                    display: "flex",
                    flexDirection: { sm: "row", xs: "column" },
                    gap: "20px",
                  }}
                >
                  <FormControl fullWidth>
                    <TextValidator validators={['required']} errorMessages={['this field is required', 'name is not valid']}
                      type="text"
                      name="name"
                      size="medium"
                      label={t("popup.name")}
                      transformOrigin="right"
                      variant="outlined"
                      onChange={handleStateChange}
                      value={mailerState.name}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextValidator validators={['required', 'isEmail']} errorMessages={['this field is required', 'email is not valid']}
                      name="email"
                      label={t("popup.email")}
                      type="email"
                      onChange={handleStateChange}
                      variant="outlined"
                      value={mailerState.email}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextValidator validators={['required','isNumber']}
                     errorMessages={['this field is required','phone number is not valid' ]} 
                      name="phone"
                      label={t("popup.phone")}
                      onChange={handleStateChange}
                      variant="outlined"
                      value={mailerState.phone}
                    />
                  </FormControl>
                </Box>
              </div>
              <div className="select-input">
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: { sm: "row", xs: "column" },
                    gap: "20px",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel>{t("popup.location")}</InputLabel>

                    <Select
                      name="location"
                      type="text"
                      variant="outlined"
                      value={mailerState.location}
                      onChange={handleStateChange}
                      style={{ fontSize: "14px" }}
                      InputLabelProps={{
                        sx: {
                          color: "#003566",
                          textTransform: "capitalize",
                          padding: "20px",
                        },
                      }}
                     // placeholder={t("popup.location")}
                     // autoWidth
                      
                    >
                      {/* <MenuItem disabled value="">
                        <em>Placeholder</em>
                      </MenuItem> */}
                      <MenuItem value="North">
                        {t("popup.locationOption1")}
                      </MenuItem>
                      <MenuItem value="Center">
                        {t("popup.locationOption2")}
                      </MenuItem>
                      <MenuItem value="South">
                        {t("popup.locationOption3")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel>{t("popup.purpose")}</InputLabel>
                    <Select
                      name="purpose"
                      type="text"
                      value={mailerState.purpose}
                      onChange={handleStateChange}
                      style={{ fontSize: "14px" }}
                    >
                      <MenuItem value="Invest">Invest</MenuItem>
                      <MenuItem value="Buy">Buy</MenuItem>
                      <MenuItem value="Sell">Sell</MenuItem>
                      <MenuItem value="Information">Information</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel>{t("popup.time")}</InputLabel>
                    <Select
                      name="time"
                      value={mailerState.time}
                      onChange={handleStateChange}
                      style={{ fontSize: "14px" }}
                    >
                      <MenuItem value="1 year">1 year</MenuItem>
                      <MenuItem value="2 years">2 years</MenuItem>
                      <MenuItem value="3 years">3 years</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="fulwidth-input">
                <Box
                  sx={{
                    maxWidth: "100%",
                  }}
                >
                  <TextValidator validators={['required']} errorMessages={['this field is required']}
                    fullWidth
                    label={t("popup.message")}
                    id="fullWidth"
                    name="message"
                    type="text"
                    onChange={handleStateChange}
                    variant="outlined"
                    value={mailerState.message}
                    style={{ fontSize: "18px" }}
                  />
                </Box>
              </div>
            </div>
            {/* <p className="error-message">{message}</p> */}
            <div className="popup-btn">
              <Button
                sx={{
                  color: "#fff",
                  // width:"187px",
                  height: "60px",
                  border: "1px solid #1179EC",
                  padding: "16px 24px",
                  borderRadius: "16px",
                  background:
                    "linear-gradient(115deg, #1179EC 0%, rgba(18, 122, 237, 0.69) 100%);",
                  textTransform: "capitalize",
                }}
                variant="outlined"
                type="submit"
              >
                <div className="btn-text">
                  <span>{t("popup.btn")}</span> <img src={Send} alt="" />
                </div>
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Popup;
