import React, { useEffect, useState } from "react";
import "./Footer.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Slider from "@mui/material/Slider";
import Checkbox from "@mui/material/Checkbox";
import Send from "../../Assets/Icons/Send.svg";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TabNav from "./TabNav";
import FormContent from "./FormContent";
import { BsGlobe } from "react-icons/bs";
import i18next from "i18next";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useFlip, FlipProvider } from "react-easy-flip";
import Fade from "react-reveal/Fade";
const Footer = (props) => {
  /**** Form Submit Function ****/
  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
    purpose: "",
    time: "",
    message: "",
  });
  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    // console.log(mailerState)
  }

  // const handleSubmit = (event) => {
  //   event.preventDefault()

  const submitEmail1 = async (e) => {
    e.preventDefault();
    console.log({ mailerState });
    const response = await fetch("http://localhost:8000/sendform1", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ mailerState }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const resData = await res;
        console.log(resData);
        if (resData.status === "success") {
          alert("Message Sent");
        } else if (resData.status === "fail") {
          alert("Message failed to send");
        }
      })
      .then(() => {
        setMailerState({
          email: "",
          name: "",
          phone: "",
          location: "",
          purpose: "",
          time: "",
          message: "",
        });
      });
    console.log(setMailerState);
  };
  // Form - 2

  const submitEmail2 = async (e) => {
    e.preventDefault();
    console.log({ mailerState });
    const response = await fetch("http://localhost:8000/sendform2", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ mailerState }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const resData = await res;
        console.log(resData);
        if (resData.status === "success") {
          alert("Message Sent");
        } else if (resData.status === "fail") {
          alert("Message failed to send");
        }
      })
      .then(() => {
        setMailerState({
          email: "",
          name: "",
          phone: "",
          location: "",
          purpose: "",
          time: "",
          message: "",
        });
      });
    console.log(setMailerState);
  };

  // Form - 3

  const submitEmail3 = async (e) => {
    e.preventDefault();
    console.log({ mailerState });
    const response = await fetch("http://localhost:8000/sendform3", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ mailerState }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const resData = await res;
        console.log(resData);
        if (resData.status === "success") {
          alert("Message Sent");
        } else if (resData.status === "fail") {
          alert("Message failed to send");
        }
        console.log(resData);
      })
      .then(() => {
        setMailerState({
          email: "",
          name: "",
          phone: "",
          location: "",
          purpose: "",
          time: "",
          message: "",
        });
      });
    console.log(setMailerState);
  };
  //** checkBox State Handler Function */
  // const [checkedState, setCheckedState] = useState (false)
  // const handleChecked = (event: React.ChangeEvent<HTMLInputElement>)=>{
  //   setCheckedState(event.target.checked)
  // }
  const { t } = useTranslation();
  const languages = [
    { code: "he", name: "עִברִית", country_code: "il", dir: "rtl" },
    { code: "en", name: "English", country_code: "gb" },
  ];
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage]);
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [num, setNum] = React.useState("");

  const handleInput = (event) => {
    setNum(event.target.value);
  };
  const [num2, setNum2] = React.useState("");

  const handleInput2 = (event) => {
    setNum2(event.target.value);
  };

  const [activeTab, setActiveTab] = useState("tab1");
  // const handleTab1 = () => {
  //   // update the state to tab1
  //   setActiveTab("tab1");
  // };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
  };
  const flipRootId = "flipRoot";
  useFlip(flipRootId);
  return (
    <div className=" form-wrapper">
      <div className="footer-top container-wrapper">
        <Fade bottom cascade distance="20%">
          <div className="container-boxx">
            <div className="footer-form-title">{t("footerForm.title")}</div>
            <div className="footer-form-desc">{t("footerForm.text")}</div>
          </div>
        </Fade>
      </div>
      <div className="footer-color-bg container-wrapper">
        <div className="container-boxx">
          <div className="form-inner footer-form-inner">
            <div className="form-tabs">
              <FlipProvider>
                <ul className="form-nav-tabs" data-flip-root-id={flipRootId}>
                  <TabNav
                    title={t("footerForm.tab1")}
                    id="tab1"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    data-flip-id="hightlight"
                  />
                  <TabNav
                    title={t("footerForm.tab2")}
                    id="tab2"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  <TabNav
                    title={t("footerForm.tab3")}
                    id="tab3"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </ul>
              </FlipProvider>
              <hr className="tabs-hr-line" />
            </div>

            <FormContent id="tab1" activeTab={activeTab}>
              <Fade bottom distance="5%">
                {" "}
                <div className="form-elements">
                  <div className="input-fields">
                    <div className="top-input">
                      <Box
                        component="form"
                        fullWidth
                        sx={{
                          display: "flex",
                          flexDirection: { sm: "row", xs: "column" },
                          gap: "20px",
                        }}
                      >
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            name="name"
                            label={t("footerForm.form1.name")}
                            variant="outlined"
                            value={mailerState.name}
                            onChange={handleStateChange}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <TextField
                            name="email"
                            type="email"
                            label={t("footerForm.form1.email")}
                            variant="outlined"
                            value={mailerState.email}
                            onChange={handleStateChange}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <TextField
                            name="phone"
                            type="number"
                            label={t("footerForm.form1.phone")}
                            variant="outlined"
                            value={mailerState.phone}
                            onChange={handleStateChange}
                          />
                        </FormControl>
                      </Box>
                    </div>
                    <div className="select-input">
                      <Box
                        component="form"
                        sx={{
                          display: "flex",
                          flexDirection: { sm: "row", xs: "column" },
                          gap: "20px",
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel>
                            {t("footerForm.form1.location")}
                          </InputLabel>
                          <Select
                            name="location"
                            type="text"
                            value={mailerState.location}
                            onChange={handleStateChange}
                            InputLabelProps={{
                              sx: {
                                color: "#003566",
                                textTransform: "capitalize",
                                padding: "20px",
                              },
                            }}
                            sx={(theme) => ({
                              "& .MuiOutlinedInput-notchedOutline": {
                                px: 10.5,
                              },
                            })}
                          >
                            <MenuItem value="צָפוֹן">צָפוֹן</MenuItem>
                            <MenuItem value="דָרוֹם">דָרוֹם</MenuItem>
                            <MenuItem value="מֶרְכָּז">מֶרְכָּז</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel>
                            {t("footerForm.form1.purpose")}
                          </InputLabel>
                          <Select
                            name="purpose"
                            type="text"
                            value={mailerState.purpose}
                            label="Num"
                            onChange={handleStateChange}
                          >
                            <MenuItem value="לִקְנוֹת">לִקְנוֹת</MenuItem>
                            <MenuItem value="מכירה">מכירה</MenuItem>
                            <MenuItem value="השכרה">השכרה</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            {t("footerForm.form1.time")}
                          </InputLabel>
                          <Select
                            name="time"
                            value={mailerState.time}
                            label="Num2"
                            onChange={handleStateChange}
                            style={{ fontSize: "14px" }}
                          >
                            <MenuItem value="1 year">1 year</MenuItem>
                            <MenuItem value="2 years">2 years</MenuItem>
                            <MenuItem value="3 years">3 years</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div className="slider-input-buy">
                      <Box
                        className="slider1-input"
                        sx={{ width: "100%", paddingBottom: "24px" }}
                      >
                        <div className="slider-intro">
                          <div className="slider-intro-title">
                            {t("footerForm.form1.sliderTitle1")}
                          </div>
                          <div className="slider-intro-text">
                            {t("footerForm.form1.sliderText1")}
                          </div>
                        </div>
                        <Slider
                          size="small"
                          sx={{
                            "& .MuiSlider-rail": {
                              color: "#acc4e4",
                            },
                          }}
                          aria-label="Always visible "
                          defaultValue={500000}
                          min={500000}
                          max={20000000}
                          step={500000}
                          valueLabelDisplay="on"
                        />
                      </Box>
                      <Box
                        className="slider2-input"
                        sx={{ width: "100%", paddingBottom: "24px" }}
                      >
                        <div className="slider-intro">
                          <div className="slider-intro-title">
                            {t("footerForm.form1.sliderTitle2")}
                          </div>
                          <div className="slider-intro-text">
                            {t("footerForm.form1.sliderText2")}
                          </div>
                        </div>
                        <Slider
                          size="small"
                          sx={{
                            "& .MuiSlider-rail": {
                              color: "#acc4e4",
                            },
                          }}
                          aria-label="Always visible "
                          defaultValue={500000}
                          // getAriaValueText={valuetext}
                          min={500000}
                          max={2000000}
                          step={500000}
                          valueLabelDisplay="on"
                        />
                      </Box>
                    </div>
                    <div className="fulwidth-input">
                      <Box
                        sx={{
                          maxWidth: "100%",
                        }}
                      >
                        <TextField
                          fullWidth
                          label={t("footerForm.form1.textArea")}
                          id="fullWidth"
                          name="message"
                          type="text"
                          onChange={handleStateChange}
                        />
                      </Box>
                    </div>
                    <div className="checkbox-buy">
                      <FormControlLabel
                        control={<Checkbox />}
                        label={t("footerForm.form1.checkBox")}
                      />
                    </div>
                  </div>
                  <div className="send-btn">
                    <Button
                      sx={{
                        color: "#fff",
                        // width:"187px",
                        height: "60px",
                        border: "1px solid #1179EC",
                        padding: "16px 24px",
                        borderRadius: "16px",
                        background:
                          "linear-gradient(115deg, #1179EC 0%, rgba(18, 122, 237, 0.69) 100%);",
                        textTransform: "capitalize",
                      }}
                      variant="outlined"
                      onClick={submitEmail1}
                    >
                      <div className="btn-text">
                        <span>{t("footerForm.form1.btn")} </span>{" "}
                        <img src={Send} alt="" />
                      </div>
                    </Button>
                  </div>
                </div>
              </Fade>
            </FormContent>

            <FormContent id="tab2" activeTab={activeTab}>
              <Fade bottom distance="5%">
                <div className="form-elements">
                  <div className="input-fields">
                    <div className="top-input">
                      <Box
                        component="form"
                        fullWidth
                        sx={{
                          display: "flex",
                          flexDirection: { sm: "row", xs: "column" },
                          gap: "20px",
                        }}
                      >
                        <FormControl fullWidth>
                          <TextField
                            label={t("footerForm.form1.name")}
                            variant="outlined"
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <TextField
                            label={t("footerForm.form1.email")}
                            variant="outlined"
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <TextField
                            // id="outlined-basic"
                            label={t("footerForm.form1.phone")}
                            variant="outlined"
                          />
                        </FormControl>
                      </Box>
                    </div>
                    <div className="select-input">
                      <Box
                        component="form"
                        sx={{
                          display: "flex",
                          flexDirection: { sm: "row", xs: "column" },
                          gap: "20px",
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel> {t("footerForm.form2.city")}</InputLabel>
                          <Select
                            value={age}
                            onChange={handleChange}
                            InputLabelProps={{
                              sx: {
                                color: "#003566",
                                textTransform: "capitalize",
                                padding: "20px",
                              },
                            }}
                            sx={(theme) => ({
                              "& .MuiOutlinedInput-notchedOutline": {
                                px: 10.5,
                              },
                            })}
                          >
                            <MenuItem value={1}>Atlanta</MenuItem>
                            <MenuItem value={2}>Georgia</MenuItem>
                            <MenuItem value={3}>New York</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            {t("footerForm.form2.address")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={num}
                            label="Num"
                            onChange={handleInput}
                          >
                            <MenuItem value={10}>Buy</MenuItem>
                            <MenuItem value={20}>Sell</MenuItem>
                            <MenuItem value={30}>Rent</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            {t("footerForm.form2.apartNo")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={num2}
                            label="Num2"
                            onChange={handleInput2}
                          >
                            <MenuItem value={100}>1</MenuItem>
                            <MenuItem value={200}>2</MenuItem>
                            <MenuItem value={300}>3</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div>
                      <Box
                        className="slider-input"
                        sx={{ width: "100%", paddingBottom: "24px" }}
                      >
                        <div className="slider-intro">
                          <div className="slider-intro-title">
                            {t("footerForm.form2.value")}
                          </div>
                          <div className="slider-intro-text">
                            {t("footerForm.form2.sliderAmount")}
                          </div>
                        </div>
                        <Slider
                          size="medium"
                          sx={{
                            "& .MuiSlider-rail": {
                              color: "#acc4e4",
                            },
                          }}
                          aria-label="Always visible "
                          defaultValue={80}
                          // getAriaValueText={valuetext}
                          min={0}
                          max={2000000}
                          step={500000}
                          valueLabelDisplay="on"
                        />
                      </Box>
                    </div>
                    <div className="fulwidth-input">
                      <Box
                        sx={{
                          maxWidth: "100%",
                        }}
                      >
                        <TextField
                          fullWidth
                          label={t("footerForm.form2.questionText")}
                          id="fullWidth"
                        />
                      </Box>
                    </div>
                  </div>
                  <div className="send-btn">
                    <Button
                      sx={{
                        color: "#fff",
                        // width:"187px",
                        height: "60px",
                        border: "1px solid #1179EC",
                        padding: "16px 24px",
                        borderRadius: "16px",
                        background:
                          "linear-gradient(115deg, #1179EC 0%, rgba(18, 122, 237, 0.69) 100%);",
                        textTransform: "capitalize",
                      }}
                      variant="outlined"
                      onClick={submitEmail2}
                    >
                      <div className="btn-text">
                        <span>{t("footerForm.form1.btn")}</span>{" "}
                        <img src={Send} alt="" />
                      </div>
                    </Button>
                  </div>
                </div>
              </Fade>
            </FormContent>
            <FormContent id="tab3" activeTab={activeTab}>
              <Fade bottom distance="5%">
                <div className="form-elements">
                  <div className="input-fields">
                    <div className="top-input">
                      <Box
                        component="form"
                        fullWidth
                        sx={{
                          display: "flex",
                          flexDirection: { sm: "row", xs: "column" },
                          gap: "20px",
                        }}
                      >
                        <FormControl fullWidth>
                          <TextField
                            // id="outlined-basic"
                            label={t("footerForm.form1.name")}
                            variant="outlined"
                            // InputLabelProps={{ shrink: false }}
                            // placeholder="Placeholder Text"
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <TextField
                            // id="outlined-basic"
                            label={t("footerForm.form1.email")}
                            variant="outlined"
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <TextField
                            // id="outlined-basic"
                            label={t("footerForm.form1.phone")}
                            variant="outlined"
                          />
                        </FormControl>
                      </Box>
                    </div>
                    <div className="select-input">
                      <Box
                        component="form"
                        sx={{
                          display: "flex",
                          flexDirection: { sm: "row", xs: "column" },
                          gap: "20px",
                        }}
                      >
                        <FormControl fullWidth dir="rtl">
                          <InputLabel>
                            {t("footerForm.form1.location")}
                          </InputLabel>
                          <Select
                            value={age}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: false }}
                            // InputLabelProps={{
                            //   sx: {
                            //     color: "#003566",
                            //     textTransform: "capitalize",
                            //     padding: "20px",
                            //   },
                            // }}
                            sx={(theme) => ({
                              "& .MuiOutlinedInput-notchedOutline": {
                                px: 10.5,
                              },
                            })}
                          >
                            <MenuItem value={1}>צָפוֹןa</MenuItem>
                            <MenuItem value={2}>מֶרְכָּז</MenuItem>
                            <MenuItem value={3}>דָרוֹם</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            {t("footerForm.form1.purpose")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="fullWidth"
                            value={num}
                            label="Num"
                            onChange={handleInput}
                          >
                            <MenuItem value={10}>Buy</MenuItem>
                            <MenuItem value={20}>Sell</MenuItem>
                            <MenuItem value={30}>Rent</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            {t("footerForm.form1.time")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            // id="demo-simple-select"
                            id="fullWidth"
                            value={num2}
                            label="Num2"
                            onChange={handleInput2}
                          >
                            <MenuItem value={100}>1</MenuItem>
                            <MenuItem value={200}>2</MenuItem>
                            <MenuItem value={300}>3</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div className="title-transition">
                      {t("footerForm.form3.text")}{" "}
                    </div>
                    <div className="checkboxes">
                      <Box
                        className="checkboxes-wrap"
                        component="form"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                          control={<Checkbox />}
                          label={t("footerForm.form3.checkbox1")}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label={t("footerForm.form3.checkbox2")}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label={t("footerForm.form3.checkbox3")}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label={t("footerForm.form3.checkbox4")}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label={t("footerForm.form3.checkbox4")}
                        />
                      </Box>
                    </div>
                    <div className="fulwidth-input">
                      <Box
                        sx={{
                          maxWidth: "100%",
                        }}
                      >
                        <TextField
                          fullWidth
                          label={t("footerForm.form2.questionText")}
                          id="fullWidth"
                        />
                      </Box>
                    </div>
                  </div>
                  <div className="send-btn">
                    <Button
                      sx={{
                        color: "#fff",
                        // width:"187px",
                        height: "60px",
                        border: "1px solid #1179EC",
                        padding: "16px 24px",
                        borderRadius: "16px",
                        background:
                          "linear-gradient(115deg, #1179EC 0%, rgba(18, 122, 237, 0.69) 100%);",
                        textTransform: "capitalize",
                      }}
                      variant="outlined"
                      onClick={submitEmail3}
                    >
                      <div className="btn-text">
                        <span>Send </span> <img src={Send} alt="" />
                      </div>
                    </Button>
                  </div>
                </div>
              </Fade>
            </FormContent>
          </div>
        </div>
        <div className="container-boxx">
          <div className="footer-contact">
            <div className="phone-no">+972527259901</div>
            <div className="terms">Terms of use</div>
            <div className="email-address">Info@datapoints.co.il</div>
            <div className="dropdown">
              <button
                className="btn btn-link dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="dropdownMenuButton1"
              >
                <BsGlobe size={24} />
              </button>
              <ul className="dropdown-menu">
                {languages.map(({ code, name, country_code }) => (
                  <li key={country_code}>
                    <button
                      className="dropdown-item d-flex justify-content-start"
                      onClick={() => i18next.changeLanguage(code)}
                      disabled={code != currentLanguageCode}
                    >
                      <span
                        className={`fi fi-${country_code} mx-3 `}
                        style={{
                          opacity: code != currentLanguageCode ? 0.5 : 1,
                        }}
                      ></span>
                      {name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
