import React, { useState } from "react";
import "./Banner.scss";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Chart from "../../Assets/Icons/Chart.svg";
import Popup from "./Popup";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';

const Banner = () => {
  const { t } = useTranslation();
  const [year, setYear] = React.useState(500000);
  const [showButton, setShowButton] = useState(true);
  const [show, setShow] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const toggleButton = () => {
    setShowButton(!showButton);
  };

  return (
    <div className="banner-container container-wrapper main-container">
      <div className="banner-content container-boxx">
        <Fade bottom cascade distance="10%">
          <div className="content-wrapper">
            <div className=" headline ">
              <h1>{t("Banner.title")}</h1>
            </div>
            <div className="banner-box">
              <div className="sliders">
                <div className="slider-text">
                  <div className="text-title">{t("Banner.sliderText1")}</div>
                  <div className="text-desc">{t("Banner.sliderDesc1")}</div>
                </div>
                <div className="slider-wrap">
                  <Slider
                    sx={{
                      "& .MuiSlider-rail": {
                        color: "#acc4e4",
                      },
                    }}
                    // ValueLabelComponent={CustomTooltip}
                    valueLabelDisplay="on"
                    min={0}
                    max={2000000}
                    step={500000}
                  />
                </div>
              </div>
              <div className="sliders">
                <div className="slider-text">
                  <div className="text-title">{t("Banner.sliderText2")}</div>
                  <div className="text-desc"> {t("Banner.sliderDesc2")}</div>
                </div>
                <div className="slider-wrap">
                  <Slider
                    sx={{
                      "& .MuiSlider-rail": {
                        color: "#acc4e4",
                      },
                    }}
                    size="medium"
                    valueLabelDisplay="on"
                    min={1}
                    max={5}
                    step={1}
                  />
                </div>
              </div>
              <div className="dropdown-form">
                <div className="dropdown-text">
                  <div className="text-title">{t("Banner.selectText3")}</div>
                  <div className="text-desc">{t("Banner.selectDesc3")}</div>
                </div>
                <div className="dropdown-wrap">
                  <FormControl sx={{ m: 1, width: "100%" }} className="selector">
                    <Select
                      sx={{ width: "100%" }}
                      value={year}
                      onChange={handleChange}
                      placeholder="which area you want?"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        <em>Region 1</em>
                      </MenuItem>
                      <MenuItem value={10}>Region 2</MenuItem>
                      <MenuItem value={20}>Region 3</MenuItem>
                      <MenuItem value={30}>Region 4</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <div className="banner-text-box-container banner-text-box-wrapper">
          <div className="text-box box-1 floating">
            <div className="text-box-title">{t("Banner.float")}</div>
            <div className="text-box-value">{t("Banner.profitValue1")}</div>
            <div className="text-box-desc">{t("Banner.profitTime1")}</div>
            <span className="box-arrow"></span>
          </div>
          <div className="text-box box-2 floating">
            <div className="text-box-title">{t("Banner.float")}</div>
            <div className="text-box-value">{t("Banner.profitValue2")}</div>
            <div className="text-box-desc">{t("Banner.profitTime2")}</div>
            <span className="box-arrow"></span>
          </div>
          <div className="text-box box-3 floating2">
            <div className="text-box-title">{t("Banner.float")}</div>
            <div className="text-box-value">{t("Banner.profitValue3")}</div>
            <div className="text-box-desc">{t("Banner.profitTime3")}</div>
            <span className="box-arrow"></span>
          </div>
        </div>
      </div>

      <div className="calculate-wrapper container-boxx">
        <div className="one">
          <div className="profit">
            <hr className="profit-hr" />
            <span className="profit-title">{t("Banner.profit")}</span>
            <hr className="profit-hr" />
          </div>
          {show && (
            <Flip bottom cascade collapse >
              <div className="calculate">
                <div className="result">{t("Banner.amount")}</div>
                <div className="result-desc">{t("Banner.remark")}</div>
                <Button
                  className="banner-btn"
                  sx={{
                    color: "#fff",
                    // width:"187px",
                    height: "60px",
                    border: "2px solid white",
                    padding: "16px 24px",
                    borderRadius: "16px",
                    background:
                      "linear-gradient(115deg, rgba(255, 255, 255, 0.39) 0%, rgba(255, 255, 255, 0.19) 100%);",
                    textTransform: "capitalize",
                  }}
                  variant="outlined"
                  onClick={() => setButtonPopup(true)}
                >
                  <span className="banner-btn2">{t("Banner.btn2")}</span>
                </Button>
                {/* <IconButton
                  aria-label="fingerprint"
                  color="Primary"
                  onClick={() => {
                    setShow(show);
                    setButtonPopup(false);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton> */}
              </div>
            </Flip>
          )}

          <Popup trigger={buttonPopup} setTrigger={setButtonPopup}></Popup> 
          <div className="profit-btn">
            {!show && (
              <Button
                className="banner-btn "
                sx={{
                  color: "#fff",
                  // width:"187px",
                  height: "60px",
                  border: "2px solid white",
                  padding: "16px 24px",
                  borderRadius: "16px",
                  background:
                    "linear-gradient(115deg, rgba(255, 255, 255, 0.39) 0%, rgba(255, 255, 255, 0.19) 100%);",
                  textTransform: "capitalize",
                }}
                variant="outlined"
                onClick={() => {
                  setShow(!show);
                }}
              >
                <div className="btn-text">
                  <img src={Chart} alt="" />
                  <span>{t("Banner.btn1")} </span>
                </div>
              </Button>
            )}
          </div>
        </div>
        <div className="two"></div>
      </div>
    </div>
  );
};

export default Banner;
