import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Banner from '../Components/Banner/Banner'
import Property from '../Components/Property/Property'
import Services from '../Components/Services/Services'
import Whyus from '../Components/Whyus/Whyus'
import Team from '../Components/Team/Team'
import Footer from '../Components/Footer/Footer'

const Home = () => {
  return (
    <div>
      <Navbar/>
      <Banner/>
      <Property />
      <Services />
      <Whyus />
      <Team />
      <div id="footer">
        <Footer />
      </div>
    </div>
  )
}

export default Home