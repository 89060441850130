import React from "react";
import "./Team.scss";
import team1 from "../../Assets/Images/team1.png";
import team2 from "../../Assets/Images/team2.png";
import team3 from "../../Assets/Images/team3.png";
import team4 from "../../Assets/Images/team4.png";
import linkedin from "../../Assets/Icons/linkedin.svg";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const Team = () => {
  const { t } = useTranslation();
  return (
    <div className="container-wrapper">
      <div className="container-boxx team-container ">
        <div className="team-title">
          <div>{t("whyus.teamTitle")}</div>
        </div>
        <div className="team-wrapper">
          <Fade bottom cascade distance="20%">
            <div className="team-top">
              <div className="team-content">
                <div className="team-img">
                  <img src={team1} alt="" />
                </div>
                <div className="team-name">Isaac Rivers</div>
                <div className="position">Marketing</div>
                <div className="linkedin">
                  <img src={linkedin} alt="" />
                </div>
              </div>
              <div className="team-content">
                <div className="team-img">
                  <img src={team2} alt="" />
                </div>
                <div className="team-name">Zoe Wyatt</div>
                <div className="position">Strategy</div>
                <div className="linkedin">
                  <img src={linkedin} alt="" />
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom cascade distance="20%" delay={500}>
            <div className="team-bottom">
              <div className="team-content">
                <div className="team-img">
                  <img src={team3} alt="" />
                </div>
                <div className="team-name">Johnny Shepherd</div>
                <div className="position">Finance & Operations</div>
                <div className="linkedin">
                  <img src={linkedin} alt="" />
                </div>
              </div>
              <div className="team-content">
                <div className="team-img">
                  <img src={team4} alt="" />
                </div>
                <div className="team-name">Sharon Robbins</div>
                <div className="position">CEO</div>
                <div className="linkedin">
                  <img src={linkedin} alt="" />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Team;
