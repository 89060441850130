import React from "react";
import Slider from "react-slick";
import "./Property.scss";
import img1 from "../../Assets/Images/cardImg1.png";
import img2 from "../../Assets/Images/cardImg2.png";
import img3 from "../../Assets/Images/cardImg3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Button from "@mui/material/Button";
import Phone from "../../Assets/Icons/Phone.svg";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const Property = () => {
  const { t } = useTranslation();

  const handleScroll = () => {
    const footer = document.getElementById("footer");
    footer.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="container-wrapper property-main">
      <div className="property-container container-boxx">
        <Fade bottom distance="20%">
          <div className="property-title">
            <h2>
              {t("property.title")}
              <span className="blue-txt">{t("property.blue")}</span>
              {t("property.title2")}
            </h2>
          </div>
        </Fade>
        <div className="property-slider">
          <Fade cascade bottom distance="20%">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                576: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                {" "}
                <Fade cascade bottom distance="20%">
                  <div className="card-wrapper">
                    <div className="img-container">
                      <img src={img1} alt="" />
                    </div>
                    <div className="prop-card-content">
                      <div className="prop-card-title">
                        {t("property.cardTitle")}
                      </div>
                      <div className="prop-card-text">
                        {t("property.cardDesc")}
                      </div>
                    </div>
                  </div>
                </Fade>
              </SwiperSlide>
              <SwiperSlide>
                <Fade cascade bottom distance="20%" delay={600}>
                  <div className="card-wrapper">
                    <div className="img-container">
                      <img src={img2} alt="" />
                    </div>
                    <div className="prop-card-content">
                      <div className="prop-card-title">
                        {t("property.cardTitle2")}
                      </div>
                      <div className="prop-card-text">
                        {t("property.cardDesc2")}
                      </div>
                    </div>
                  </div>
                </Fade>
              </SwiperSlide>
              <SwiperSlide>
                <Fade cascade bottom distance="20%" delay={700}>
                  <div className="card-wrapper">
                    <div className="img-container">
                      <img src={img3} alt="" />
                    </div>
                    <div className="prop-card-content">
                      <div className="prop-card-title">
                        {t("property.cardTitle3")}
                      </div>
                      <div className="prop-card-text">
                        {t("property.cardDesc3")}
                      </div>
                    </div>
                  </div>
                </Fade>
              </SwiperSlide>
            </Swiper>
          </Fade>
        </div>
        <Fade cascade bottom distance="20%">
          <div className="proerty-desc">{t("property.detailDesc")}</div>
          <div className="property-btn">
            <Button
              className="blue-btn"
              sx={{
                color: "#fff",
                // width:"187px",
                height: "60px",
                border: "2px solid #1179EC",
                padding: "16px 24px",
                borderRadius: "16px",
                background:
                  "linear-gradient(115deg, #1179EC 0%, rgba(18, 122, 237, 0.69) 100%);",
                textTransform: "capitalize",
              }}
              variant="outlined"
              onClick={handleScroll}
            >
              <div className="btn-text">
                <span>{t("Navbar.contact")} </span> <img src={Phone} alt="" />
              </div>
            </Button>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Property;
